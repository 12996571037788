/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'MyriadPro-Regular';
  src: url('assets/fonts/MyriadPro-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/MyriadPro-Regular.otf') format('opentype'),
    url('assets/fonts/MyriadPro-Regular.woff') format('woff'),
    url('assets/fonts/MyriadPro-Regular.ttf') format('truetype'),
    url('assets/fonts/MyriadPro-Regular.svg#MyriadPro-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadPro-Bold';
  src: url('assets/fonts/MyriadPro-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/MyriadPro-Bold.otf') format('opentype'),
    url('assets/fonts/MyriadPro-Bold.woff') format('woff'),
    url('assets/fonts/MyriadPro-Bold.ttf') format('truetype'),
    url('assets/fonts/MyriadPro-Bold.svg#MyriadPro-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

a {
  cursor: pointer;
}

ui,
li {
  padding: 0px;
  list-style: none;
}
